.Correctness {
  margin-left: 0px;
  margin-right: 0px;
  width: 320px;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 29px;
  padding-right: 29px;
  box-sizing: border-box;
  min-height: 395px;
}

.Correctness__offBy {
  font-size: 28px;
  color: white;
  font-weight: 500;
  line-height: 46px;
  margin-top: 4px;
  position: absolute;
  text-align: center;
  width: 100%;
  top: 50%;
}

.Correctness__button {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #00bbb0;
  color: white;
  border: none;
  height: 60px;
  border-radius: 8px;
  font-family: Jost;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  cursor: pointer;
}

.Correctness__container {
  position: relative;
  height: 100%;
}

.Correctness__question {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  margin-top: 4px;
  margin-bottom: 35px;
}

@media screen and (max-width: 760px) {
  .Correctness {
    display: block;
    width: 100%;
  }
}
