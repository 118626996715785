@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,400;0,500;1,400&display=swap');

body {
  margin: 0;
  font-family: 'Jost', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #1d1e21;
  width: 100vw;
  height: 100vh;
}
